import React, { useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { generatePath, matchPath } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';

import book from 'routes/book';

import { getCRMTitle } from 'crms/helpers';

import { createLabel } from 'components/clientClaim/claimPicker';

import { i18authGlobalInstance } from 'localization/otherInstance';

import { getLocale } from 'bus/auth/selectors';

import { PAYMENT_TYPES } from 'helpers/dictionary';
import { CRM_TYPES } from '../../../../crms/constants';

const titlesList = {
  [book.main]: 'BOOK_MAIN',
  [book.client.path]: 'BOOK_CLIENT',
  [book.client.children.lead]: 'BOOK_CLIENT_LEAD',
  [book.client.children.booking]: 'BOOK_CLIENT_BOOKING',
  [book.addClient]: 'BOOK_ADD_CLIENT',
  [book.details.path]: 'BOOK_DETAILS',
  [book.details.children.profile.path]: 'BOOK_DETAILS_PROFILE',
  [book.details.children.settingUser]: 'BOOK_DETAILS_SETTING_USER',
  [book.details.children.addUser]: 'BOOK_DETAILS_SETTING_USER',
  [book.details.children.templates.path]: 'BOOK_DETAILS_TEMPLATES',
  [book.details.children.editDoc]: 'BOOK_DETAILS_TEMPLATES',
  [book.details.children.templates.children.invoices]: 'BOOK_DETAILS_TEMPLATES_INVOICES',
  [book.details.children.templates.children.tourOperators]: 'BOOK_DETAILS_TEMPLATES_OPERATORS',
  [book.details.children.templates.children.collections]: 'BOOK_DETAILS_TEMPLATES_COLLECTIONS',
  [book.details.children.templates.children.docNumber]: 'BOOK_DETAILS_TEMPLATES_DOCNUMBER',
  [book.details.children.editInvoice]: 'BOOK_DETAILS_TEMPLATES_INVOICES',
  [book.details.children.editInvoice]: 'BOOK_DETAILS_TEMPLATES_INVOICES',
  [book.details.children.notifications.children.notify]: 'BOOK_DETAILS_NOTIFICATIONS_NOTIFY',
  [book.details.children.notifications.children.sms]: 'BOOK_DETAILS_NOTIFICATIONS_SMS',
  [book.details.children.notifications.children.mailing]: 'BOOK_DETAILS_NOTIFICATIONS_MALLING',
  [book.details.children.subscription]: 'BOOK_DETAILS_SUBSCRIPTION',
  [book.details.children.profile.children.office]: 'BOOK_DETAILS_PROFILE_OFFICE',
  [book.details.children.orders.children.tags]: 'BOOK_DETAILS_ORDERS_TAGS',
  [book.details.children.orders.children.closeReasons]: 'BOOK_DETAILS_ORDERS_CLOSEREASONS',
  [book.details.children.templates.children.fields]: 'BOOK_DETAILS_TEMPLATES_FIELDS',
  [book.details.children.integration.children.api]: 'BOOK_DETAILS_INTEGRATION_API',
  [book.details.children.integration.children.ip]: 'BOOK_DETAILS_INTEGRATION_IP',
  [book.details.children.integration.children.facebook]: 'BOOK_DETAILS_INTEGRATION_FACEBOOK',
  [book.details.children.integration.children.emails]: 'BOOK_DETAILS_INTEGRATION_EMAILS',
  [book.details.children.orders.children.claimSource]: 'BOOK_DETAILS_ORDERS_CLAIM_SOURCE',
  [book.details.children.downloadBooking.children.plugin]: 'BOOK_DETAILS_DOWNLOAD_BOOKING_PLUGIN',
  [book.details.children.downloadBooking.children.auto]: 'BOOK_DETAILS_DOWNLOAD_BOOKING_AUTO',
  [book.details.children.dump]: 'BOOK_DETAILS_DUMP',
  [book.details.children.importAndExport]: 'BOOK_DETAILS_EXPORT_AND_IMPORT',
  [book.details.children.requisites.children.docs]: 'BOOK_DETAILS_REQUISITES_DOCS',
  [book.details.children.requisites.children.merchants]: 'BOOK_DETAILS_REQUISITES_MERCHANTS',
  [book.details.children.requisites.children.payments]: 'BOOK_DETAILS_REQUISITES_PAYMENTS',
  [book.details.children.requisites.children.bonuses]: 'BOOK_DETAILS_REQUISITES_BONUSES',
  [book.login]: 'BOOK_LOGIN',
  [book.clientClaim.children.claim]: 'BOOK_CLIENT_CLAIM',
  [book.clientClaim.children.profile]: 'BOOK_CLIENT_CLAIM_PROFILE',
  [book.clientClaim.children.file]: 'BOOK_CLIENT_CLAIM_FILE',
  [book.clientClaim.children.createClaim]: 'BOOK_CLIENT_CLAIM_CREATECLAIM',
  [book.charters.path]: __CRM__ === CRM_TYPES.nakanikuly ? 'BOOK_CHARTERS_NK' : 'BOOK_CHARTERS',
  [book.fin.path]: 'BOOK_FIN',
  [book.fin.children.rates]: 'BOOK_FIN_RATES',
  [generatePath(book.fin.children.type, { type: PAYMENT_TYPES.expense })]: 'BOOK_FIN_EXPENSE',
  [generatePath(book.fin.children.type, { type: PAYMENT_TYPES.income })]: 'BOOK_FIN_INCOME',
  [book.tourscanner.path]: __CRM__ === CRM_TYPES.nakanikuly ? 'BOOK_TOURSCANNER_NK' : 'BOOK_TOURSCANNER',
  [book.tourscanner.children.cart]: __CRM__ === CRM_TYPES.nakanikuly ? 'BOOK_TOURSCANNER_CART_NK' : 'BOOK_TOURSCANNER_CART',
  [book.tourscanner.children.collections]: __CRM__ === CRM_TYPES.nakanikuly ? 'BOOK_TOURSCANNER_COLLECTIONS_NK' : 'BOOK_TOURSCANNER_COLLECTIONS',
  [book.stat.path]: 'BOOK_STAT',
  [book.stat.children.calls]: 'BOOK_STAT_CALLS',
  [book.stat.children.log]: 'BOOK_STAT_LOG',
  [book.stat.children.salesFunnel]: 'BOOK_STAT_SALESFUNNEL',
  [book.stat.children.bonuses.path]: 'BOOK_STAT_BONUSES',
  [book.stat.children.bonuses.children.calculation]: 'BOOK_STAT_BONUSES',
  [book.news.path]: 'BOOK_NEWS',
  [book.news.children.single]: 'BOOK_NEWS',
  [book.editor.children.filebrowser]: 'BOOK_EDITOR_FILEBROWSER',
  [book.marketing.path]: 'BOOK_MARKETING',
  [book.marketing.children.add.path]: 'BOOK_MARKETING',
  [book.marketing.children.preview]: 'BOOK_MARKETING',
  [book.marketing.children.add.children.update]: 'BOOK_MARKETING',
  [book.calendar]: 'BOOK_CALENDAR',
  [book.adminBooking.path]: 'BOOK_ADMIN_BOOKING',
  [book.adminBooking.children.booking]: 'BOOK_ADMIN_BOOKING',
  [book.adminAccounting.path]: 'BOOK_ADMIN_ACCOUNTING',
  [book.adminAccounting.children.invoices]: 'BOOK_ADMIN_ACCOUNTING_INVOICES',
  [book.airtickets.path]: 'AIRLINE_TICKETS',
  [book.commission]: 'MY_COMMISSION',
  [book.commissionAccess]: 'ACCESS_TO',
  default: __CRM__ === CRM_TYPES.nakanikuly ? 'DEFAULT_NK' : 'DEFAULT',
};

export const Component = () => {
  const {
    pathname, claim, clientImm, booking, location
  } = useSelector(({ router, clientClaim, ...state }) => ({
    pathname: router.location.pathname,
    claim: clientClaim.get('claim'),
    clientImm: clientClaim.get('client'),
    booking: state.booking.getIn(['booking', 'data']),
    location: router.location
  }), shallowEqual);
  const client = clientImm.toJS();
  const { t, i18n } = useTranslation();
  const locale = useSelector(getLocale);

  const titleName = useMemo(() => {
    const claimPage = matchPath(
      pathname, { path: book.clientClaim.children.claim, exact: true }
    );
    const clientPage = matchPath(
      pathname, { path: book.clientClaim.children.profile, exact: true }
    );
    const bookingPage = matchPath(
      pathname, { path: book.adminBooking.children.booking, exact: true }
    );

    const bookingCrmPage = matchPath(
      pathname, { path: book.client.children.bookingPage, exact: true }
    );

    switch (true) {
      case Boolean(claimPage && claim): {
        return `${createLabel(claim)}`;
      }
      case Boolean(clientPage && client.name): {
        return `${client.name} ${client.city_name ? `,${client.city_name}` : ''}`;
      }
      case Boolean(bookingPage && booking): {
        return `${booking.operator.title} ${booking.op_claim_id}`;
      }
      case Boolean(location.pathname === book.news.path): {
        switch (true) {
          case (location.query.id === '2'): return 'BOOK_NEWS_BOOKING_CONDITIONS';
          case (location.query.id === '3'): return 'BOOK_NEWS_TRAINING';
          default: return 'BOOK_NEWS';
        }
      }
      case Boolean(bookingCrmPage && booking): {
        return t('BOOKING_PAGE_TITLE', {
          opClaimId: booking.op_claim_id,
          operatorTitle: booking.operator?.title,
        });
      }
      default: {
        const path = Object.keys(titlesList).find(titlePath => matchPath(pathname, {
          path: titlePath,
          exact: true,
          strict: true
        }));

        return titlesList[path] ?? getCRMTitle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, claim, client, booking]);

  useEffect(() => {
    locale && i18n.changeLanguage(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <title>
      {t(titleName)}
    </title>
  );
};

export const TitleComponent = () => {
  return (
    <I18nextProvider i18n={i18authGlobalInstance}>
      <Component />
    </I18nextProvider>
  );
};
