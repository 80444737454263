import React, { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { replace } from 'connected-react-router';

import { getIsAuth } from 'bus/auth/selectors';
import { createRefLink } from 'bus/auth/helpers';

import Menu from 'components/nav/Menu';
import UserMenu from 'components/nav/UserMenu';
import { SearchClient } from 'components/controls/SearchClient';

import { useCrmLogo } from 'crms/hooks';

import book from 'routes/book';

import { getCrmTypes } from 'crms/utils';

import { getIsEnabledCRM, SubscriptionProvider } from 'containers/molecules/subscription';

import mtLogo from 'assets/icons/mobile-logo.svg';
import mobileLogoTat from 'assets/icons/mobile-logo-tat.svg';
import mobileLogoStars from 'assets/5stars-yellow.svg';
import mobileLogoNk from 'assets/icons/nk_logo_mobile.svg';
import mobileMenu from 'assets/icons/menu-24.svg';
import mobileMenuMt from 'assets/icons/menu-24-purple.svg';
import mobileMenuMultitour from 'assets/logogomark-multitour.svg';
import PlusIcon from 'assets/icons/plus.svg';

import { isMobile } from 'react-device-detect';
import mobileClient from './img/group.svg';
import mobileSearch from './img/search.svg';

import NotifyEndingSubscriptionHeader from '../../subscription/NotifyEndingSubscriptionHeader';

import { NewYearControl } from './controls/NewYearControl';
import Styles from './styles.scss';
import { FreshdeskButton } from './components/FreshdeskButton';
import { IconButtonNew } from '../../buttons/IconButtonNew';

const C = ({ loginButtonText }) => {
  const isAuth = useSelector(getIsAuth);
  const { pathname, search } = useLocation();

  const logo = useCrmLogo();
  const mobileLogo = useCrmLogo({
    mtLogo,
    starsLogo: mobileLogoStars,
    tatLogo: mobileLogoTat,
    nkLogo: mobileLogoNk,
    multitourLogo: mobileMenuMultitour
  });
  const dispatch = useDispatch();

  const { isMt, is5Stars, isTat } = getCrmTypes();

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { t } = useTranslation(['ACTIONS', 'CLIENT', 'CLAIM', 'USER_MENU']);

  const toggleFunc = useCallback(() => setIsMenuVisible(prev => !prev), []);
  const onLogin = useCallback(() => {
    const ref = createRefLink(pathname, search);

    dispatch(replace(`${book.login}?${ref}`));
  }, [dispatch, pathname, search]);

  return (
    <header className={Styles.root}>
      <div className={classnames(Styles.headerWrapper, 'wrapper')}>
        <div
          className={classnames(Styles.mobileControls, {
            [Styles.notAuth]: !isAuth,
          })}
        >
          <div onClick={toggleFunc}>
            <div className={Styles.mobileMenuLogo} dangerouslySetInnerHTML={{ __html: mobileLogo }} />
          </div>
          {isAuth
            ? (
              <div
                className={Styles.mobileMenuToggle}
                dangerouslySetInnerHTML={{ __html: isMt ? mobileMenuMt : mobileMenu }}
                onClick={toggleFunc}
              />
            )
            : (
              <Link className={Styles.loginLink} to={book.login}>
                {t('LOGIN')}
              </Link>
            )}
        </div>
        <div className={classnames(Styles.mobileMenu, isMenuVisible && Styles.mobileVisible)}>
          <div className={Styles.mobileMenuOverlay} onClick={toggleFunc} />
          <div className={Styles.mobileMenuSidebar}>
            <Link to={book.main}>
              <div
                className={Styles.logo}
                dangerouslySetInnerHTML={{ __html: is5Stars ? mobileLogoStars : logo }}
                onClick={toggleFunc}
              />
            </Link>
            { isAuth
              ? (
                <>
                  <NewYearControl />
                  <Menu onClick={toggleFunc} />
                </>
              )
              : (
                <a className={Styles.loginLink} onClick={onLogin}>
                  { loginButtonText || t('LOGIN') }
                </a>
              )}
          </div>
        </div>

        { isAuth && (
          <div className={Styles.flex}>
            { isMobile
              && (
                <div className={Styles.mobileIcons}>
                  <Link
                    className={Styles.mobileIcon}
                    dangerouslySetInnerHTML={{ __html: mobileSearch }}
                    to={book.tourscanner.path}
                  />
                  <Link
                    className={classnames(
                      Styles.mobileIcon,
                      Styles.plusIcon
                    )}
                    dangerouslySetInnerHTML={{ __html: PlusIcon }}
                    to={book.addClient}
                  />
                  <Link
                    className={Styles.mobileIcon}
                    dangerouslySetInnerHTML={{ __html: mobileClient }}
                    to={book.client.path}
                  />
                </div>
              )}
            { !isMobile
              && (
                <>
                  <SubscriptionProvider checkAccess={getIsEnabledCRM}>
                    <Link to={book.addClient}>
                      <IconButtonNew
                        className={Styles.icon}
                        icon={PlusIcon}
                        title={t('NEW_CLAIM')}
                      />
                    </Link>
                  </SubscriptionProvider>
                  <SubscriptionProvider checkAccess={getIsEnabledCRM}>
                    <SearchClient />
                  </SubscriptionProvider>
                </>
              )}
            {isTat && <FreshdeskButton />}
            <UserMenu />
          </div>
        ) }
      </div>
      { isAuth && <NotifyEndingSubscriptionHeader /> }
    </header>
  );
};

C.displayName = 'Header';

export const Header = memo(C);
